import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import notFound from "../images/404.svg"

const NotFoundPage = () => (
  <Layout inView={true}>
    <SEO title="404: Not found" />
    <section class="text-gray-600 body-font">
      <div class="container mx-auto max-w-6xl flex px-5 pb-24 pt-28 md:flex-row flex-col items-center">
        <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-12 md:mb-0 items-center text-center">
          <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
            404: Not Found
          </h1>
          <p class="leading-relaxed">
            You just hit a route that doesn&#39;t exist... the sadness.
          </p>
        </div>
        <div class="lg:max-w-xl lg:w-full md:w-1/2 w-5/6">
          <img
            class="object-cover object-center rounded"
            alt="hero"
            src={notFound}
          />
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
